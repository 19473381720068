import React from "react"

import Layout from "../components/layout"

import * as PressKitStyles from "./presskit.module.scss"
import * as SocialStyles from "../components/social.module.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import {
    faTwitter,
    faInstagram,
    faItchIo,
} from "@fortawesome/free-brands-svg-icons"

import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Head from "../components/head"

const PressKit = () => {
    const data = useStaticQuery(graphql`
    query{
        site{
            siteMetadata{
                twitterLink
                instagramLink
                itchLink
                email
            }
        }
    }
  `)

  return(
    <Layout>
            <Head title="PressKit" description="PressKit for reviews about The War Will Win" pathname="presskit" />
            <h2 className={PressKitStyles.heading}>Press Kit</h2>
            <div class="dashed-line"></div>
            <div className={PressKitStyles.grid}>
                <div className={PressKitStyles.factsheet}>
                    <h2>Factsheet</h2>
                    <div className={PressKitStyles.box}>
                        <h3>Team</h3>
                        <div>
                            <ul>
                                <li>Drake Evans</li>
                                <li>Nabil Mohammed Shelim</li>
                                <li>Carina Kern</li>
                                <li>Diconcilio</li>
                            </ul>
                        </div>
                    </div>
                    <div className={PressKitStyles.box}>
                        <h3>Release Date</h3>
                        <div>
                            <ul>
                                <li>TBA</li>
                            </ul>
                        </div>
                    </div>
                    <div className={PressKitStyles.box}>
                        <h3>Platforms</h3>
                        <div>
                            <ul>
                                <li>Window PC</li>
                                <li>TBA</li>
                            </ul>
                        </div>
                    </div>
                    <div className={PressKitStyles.box}>
                        <h3>Price</h3>
                        <div>
                            <ul>
                                <li>TBA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={PressKitStyles.maininfo}>
                        <h2>Description</h2>
                        <p><b>The War Will Win</b> is a classic turn-based strategy game with the added uncertainty and luck of card games. Set out on an adventure to find out the mysterious disappearances at the border, join forces with other commanders, gather cards and build your perfect deck and strategy.</p>

                        <h2>History</h2>
                        <p>Our game, <b>The War Will Win</b> started as an idea by Evans Drake which he wanted to make. To bring his idea to life, he went on to hire a freelance Game Programmer and some amazing artists. As of now, the game has been in development for a while and we are soon aiming to start a Kickstarter 🚀 campaign to support our game and to check if our idea is something that people want to buy!</p>

                        <h2>Features</h2>
                        <ul>
                            <li>Choose your commanders, army, and supplies, then go to war.</li>
                            <li>Battle opposing factions as Astera to unravel the faith of the kingdom.</li>
                            <li>Every story has another side. Play as all the other factions in their version of the story.</li>
                            <li>Unlock unique structures, units and magic cards. Branching storyline, where every action you make affects the story and the faith of the kingdom.</li>
                            <li>Stray off from your mission to help other commanders, and recruit them.</li>
                            <li>Finish quests to unlock unique cards.</li>
                            <li>Win battles, get resources and upgrade your units to unlock special abilities.</li>
                            <li>Play local multiplayer mode with a friend.</li>
                        </ul>

                        <h2>Trailer</h2>
                        <div className={PressKitStyles.trailer}>
                            <iframe src="https://www.youtube.com/embed/IAcOztGrhco" title="The War Will Win (Kickstarter Demo Trailer)" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <h2>Images</h2>
                        <div className={PressKitStyles.imagegrid}>
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/PromoArt-Dateless.png" alt="Game Promo art"/></Zoom>
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/PromoArt-Textless.png" alt="Game Promo art texdless"/></Zoom>
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/Logo.png" alt="KingsCourtStudio logo"/></Zoom>
                            {/* <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/Banner-Large.png" alt="KingsCourtStudio banner"/></Zoom> */}
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/TWWWLogo.png" alt="The War Will Win logo transparent"/></Zoom>
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/TWWW-logo-darkBG.png" alt="The War Will Win logo dark background"/></Zoom>
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/TurnBasedStrategy.png" alt="Gameplay screenshot-1"/></Zoom>
                            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage className={PressKitStyles.img} src="../media/images/presskit/ChooseYourStory.png" alt="Gameplay screenshot-2"/></Zoom>
                        </div>
                        <h2>The team</h2>
                        <ul>
                            <li>Drake Evans - game designer, sound engineer, composer, and manager.</li>
                            <li>Nabil Mohammed Shelim - game designer, Programmer, technical director.</li>
                            <li>Carina Kern - Lead artist.</li>
                            <li>Diconcilio - Background artist.</li>
                        </ul>
                        <h2>Links and Social</h2>
                        <div className={PressKitStyles.social}>
                            <div><FontAwesomeIcon icon={faInstagram} className={SocialStyles.instagramstyle} size="2x"/><a href={data.site.siteMetadata.instagramLink} target="_blank" rel="noreferrer"> Instagram</a></div>
                            <div><FontAwesomeIcon icon={faTwitter} className={SocialStyles.twitterstyle} size="2x"/><a href={data.site.siteMetadata.twitterLink} target="_blank" rel="noreferrer"> Twitter</a></div>
                            <div><FontAwesomeIcon icon={faItchIo} className={SocialStyles.itchiostyle} size="2x"/><a href={data.site.siteMetadata.itchLink} target="_blank" rel="noreferrer"> Itch.io</a></div>
                            <div>
                                <FontAwesomeIcon icon={faEnvelope} className={PressKitStyles.emailstyle} size="2x"/>
                                <p>{data.site.siteMetadata.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Layout>
  )
}

export default PressKit